.headerBar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 5px 20px;
}

.title {
  flex: 1;
}

.wrapper {
  display: flex;
  flex-direction: column;
  height: 100vh;
  overflow: hidden;
}

.tableWrap {
  flex-grow: 1;
  overflow: scroll;
}

.tableFooter {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row-reverse;
  padding: 5px;
  padding-left: 10px;
  box-shadow: 0 -1px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
  z-index: 10;
}

.tableCellLeftBorder {
  border-left: 1px solid #c0c0c0 !important;
}

.rowError {
  border: 3px solid #ff6756;
}

.rowError th,
.rowError td {
  background-color: #ffddd8 !important;
}

.rowError th span.dataError,
.rowError td span.dataError {
  font-weight: 900;
  color: #c20800;
}

.objectRow {
  display: flex;
  flex-wrap: nowrap;
}

.objectRow .objectRowValue {
  white-space: nowrap;
}

.objectRow .objectRowSymbol {
  margin: 0 8px 0 4px;
}

.dateTimeContainer {
  display: flex;
  flex-wrap: wrap;
}

.dateTimeContainer .dateSection,
.dateTimeContainer .timeSection {
  white-space: nowrap;
}

.dateTimeContainer .dateSection {
  margin-right: 10px;
}
